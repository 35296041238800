import * as React from "react"
import { Box, Container, Link as LinkMui, Typography } from "@mui/material"
import NextLink from "next/link"
import Image, { StaticImageData } from "next/image"
import { BaseThemeColors } from "core/src/baseThemeColors"
import { Button } from "core/src/ui/theme-curvy/components/Button"
import { motion } from "framer-motion"

interface TextLeftImageRightProps {
    children: React.ReactNode
    title: string | React.ReactNode
    imageUrl: string | StaticImageData
    ctaUrl?: string
    ctaLabel?: string
    gutterTop?: boolean
    flip?: boolean
    alignCenter?: boolean
}

export const TextLeftImageRight: React.FunctionComponent<TextLeftImageRightProps> = ({
    children,
    title,
    imageUrl,
    ctaUrl,
    ctaLabel = "Learn More",
    gutterTop = false,
    flip = false,
    alignCenter = false,
}) => {
    return (
        <Box
            component={motion.div}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.6 }}
            sx={{
                position: "relative",
                overflow: "hidden",
                pb: 6,
                pt: gutterTop ? 14 : undefined,
            }}
        >
            <Container
                fixed
                sx={{
                    display: { lg: "flex" },
                    alignItems: alignCenter ? "center" : undefined,
                }}
            >
                <Box
                    sx={{
                        width: {
                            xs: "100%",
                            lg: "50%",
                        },
                        mb: 8,
                        pt: { lg: 2 },
                        order: flip ? 2 : undefined,
                        textAlign: {
                            xs: "center",
                            lg: "left",
                        },
                    }}
                >
                    <Typography
                        variant="h4"
                        component="h2"
                        sx={{
                            pt: 0,
                            pb: 2,

                            ml: { lg: flip ? "auto" : undefined },
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        component="div"
                        sx={{
                            ml: { lg: flip ? "auto" : undefined },
                        }}
                    >
                        {children}
                    </Typography>
                    {ctaUrl && (
                        <NextLink passHref href={ctaUrl}>
                            <Button variant="contained" color="primary">
                                {ctaLabel}
                            </Button>
                        </NextLink>
                    )}
                </Box>
                <Box
                    sx={{
                        width: {
                            xs: "100%",
                            lg: "50%",
                        },
                        mb: 8,
                        textAlign: {
                            xs: "center",
                            lg: flip ? "left" : "right",
                        },
                        order: flip ? 1 : undefined,
                    }}
                    component={motion.div}
                    initial={{ opacity: 0.5, scale: 0.95 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.6, type: "spring", delay: 0.2 }}
                >
                    <Image
                        src={imageUrl}
                        width="480"
                        height="480"
                        style={{
                            objectFit: "cover",
                            borderRadius: "1rem",
                        }}
                    />
                </Box>
            </Container>
        </Box>
    )
}
