import * as React from "react"
import { Box, Container, Icon, Typography } from "@mui/material"

import Image from "next/image"
import { animate, motion, AnimatePresence, useAnimationControls } from "framer-motion"
import { BaseThemeColors } from "core/src/baseThemeColors"
import { Button } from "core/src/ui/theme-curvy/components/Button"

const testimonials: {
    person: string
    school: string
    role: string
    content: string | React.ReactNode
}[] = [
    {
        content: `The children really enjoy the Plus journaling each morning - they like seeing how many points 
they have achieved and where in their wellbeing they perhaps need to work on. Although 
Plus covers both physical and mental wellbeing, I have noticed my children enjoy the 
opportunity to reflect on their emotions and it gives me an insight in where I can support 
them throughout the day/week/month. `,

        person: "Tash Ebbutt",
        role: "Year 6 Teacher",
        school: "Beacon church of England Primary school",
    },

    {
        content: `Plus has become a vital tool for safeguarding. Children have used it to open and express their 
feelings and speak about issues they wouldn't normally have done. This has directly 
impacted me by providing written and recorded evidence from the children which I've easily 
referenced on CPOMS. This provides a great starting point to allow an important 
conversation that might get missed in the usual busyness of the everyday classroom. `,

        person: "Tom Pettifer",
        role: "Year 6 Teacher",
        school: "Lympstone Primary School",
    },

    {
        content: `As a teacher, building relationships with children is essential in providing them a safe space 
to learn and grow. Using Plus has been particularly beneficial for children who are naturally 
quite shy or find it difficult speaking up when there is a problem - the children are able to 
flag both positive and negative things to me thus helping them to feel safe and calm within 
school. `,

        person: "Tash Ebbutt",
        role: "Year 6 Teacher",
        school: "Beacon church of England Primary school",
    },

    {
        content: `Plus gives me an insight into how our children are feeling which creates conversations to 
allow us to get our children in the right capacity to learn. We have noticed a strong 
alignment between children's daily plus points with behaviour and attainment. Since starting 
Plus I feel I get to know my children faster and better as I can get an insight into their state of 
well-being and their lives.`,

        person: "Tom Pettifer",
        role: "Year 6 Teacher",
        school: "Lympstone Primary School",
    },
]

const timeoutSeconds = 10

interface SchoolsOnTrialProps {
    children?: React.ReactNode
}

export const SchoolsOnTrial: React.FunctionComponent<SchoolsOnTrialProps> = ({ children }) => {
    const controls = useAnimationControls()

    const [isPaused, setIsPaused] = React.useState<boolean>(false)
    const [index, setIndex] = React.useState<number>(0)

    const play = (duration = timeoutSeconds) => {
        controls.start({
            scaleX: 1,
            opacity: 0.5,
            transition: {
                type: "linear",
                duration,
            },
        })
    }

    const next = () => {
        controls.set({ scaleX: 0, opacity: 1 })
        setIndex(index + 1 < testimonials.length ? index + 1 : 0)
        play()
    }

    const togglePause = () => {
        if (isPaused) {
            setIsPaused(false)
            play(timeoutSeconds / 2)
        } else {
            setIsPaused(true)
            controls.stop()
        }
    }

    React.useEffect(() => {
        if (isPaused === false) {
            next()
        }
    }, [])

    return (
        <Box
            sx={{
                pb: 10,
            }}
        >
            <Container fixed>
                <Typography
                    variant="h3"
                    component="h2"
                    sx={{
                        textAlign: "center",
                        pb: { xs: 6, lg: 8 },
                    }}
                >
                    <Typography
                        variant="inherit"
                        component="span"
                        sx={{ color: BaseThemeColors.orange.main }}
                    >
                        Schools
                    </Typography>{" "}
                    who have taken <br />a positive step
                </Typography>
                <Box
                    component={motion.div}
                    layout
                    variants={{
                        hidden: {},
                        show: {
                            transition: {
                                staggerChildren: 0.05,
                                type: "spring",
                                delay: 0.2,
                            },
                        },
                    }}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.9 }}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                    }}
                >
                    <AnimatePresence exitBeforeEnter>
                        <Box
                            key={index + "testimonial"}
                            component={motion.div}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            sx={{ maxWidth: "800px", pb: 3, mx: "auto" }}
                        >
                            <Typography variant="caption">
                                <Typography
                                    component="span"
                                    variant="h5"
                                    sx={{
                                        display: "block",
                                        fontWeight: "bold",
                                        pb: 0.5,
                                        color: BaseThemeColors.blue.main,
                                    }}
                                >
                                    {testimonials[index].school}
                                </Typography>
                            </Typography>
                            <Box sx={{ py: 2 }}>
                                <Typography variant="body1">
                                    {testimonials[index].content}
                                </Typography>
                            </Box>
                            <Typography variant="caption">
                                <Typography
                                    component="span"
                                    sx={{
                                        display: "block",
                                        fontWeight: "bold",
                                        pb: 0.5,
                                    }}
                                >
                                    {testimonials[index].person},{" "}
                                    <Typography
                                        component="span"
                                        sx={{
                                            fontWeight: "bold",
                                            color: BaseThemeColors.orange.main,
                                        }}
                                    >
                                        {testimonials[index].role}
                                    </Typography>
                                </Typography>
                            </Typography>
                        </Box>
                    </AnimatePresence>

                    <AnimatePresence>
                        <Box
                            component={motion.div}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            sx={{
                                position: "relative",
                                width: "100%",
                                maxWidth: "300px",
                                mx: "auto",
                                backgroundColor: "rgba(0, 0, 0, 0.1)",
                                borderRadius: "10px",
                                overflow: "hidden",
                            }}
                        >
                            <Box sx={{ position: "relative", height: "10px" }}>
                                <Box
                                    component={motion.div}
                                    animate={controls}
                                    onAnimationComplete={() => {
                                        next()
                                    }}
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: BaseThemeColors.gold.main,
                                        borderRadius: "10px",
                                    }}
                                ></Box>
                            </Box>
                        </Box>
                    </AnimatePresence>
                    <Box
                        sx={{
                            py: 2,
                            filter: "grayscale(1)",
                            opacity: isPaused ? 0.8 : 0.2,
                            transition: "opacity 0.2s ease",
                        }}
                    >
                        <Button onClick={() => togglePause()}>
                            <Icon>{isPaused ? "play_circle" : "pause"}</Icon>
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
